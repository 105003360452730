module.exports={
  "Loading": "Loading",
  "Back": "Back",
  "Contact us": "Contact us",
  "Let’s solve a problem": "Let’s solve a problem",
  "© 2019 code and conspire": "© 2019 code and conspire",
  "All Rights Reserved.": "All Rights Reserved.",
  "This website is open-source.": "This website is open-source.",
  "More": "More",
  "Case studies": "Case studies",
  "code and conspire": "code and conspire",
  "Selected cases": "Selected cases",
  "Oops": "Oops",
  "We apologize, an error has occured on our site. It may be temporary and you could": "We apologize, an error has occured on our site. It may be temporary and you could",
  "try again": "try again",
  "or go back to": "or go back to",
  "the homepage": "the homepage",
  "There is no page at this address. Try finding your way using the menu or from": "There is no page at this address. Try finding your way using the menu or from",
  "Manifesto": "Manifesto",
  "About & team": "About & team",
  "About us": "About us",
  "Team": "Team",
  "Cases": "Cases",
  "About": "About",
  "Close": "Close",
  "Index": "Index",
  "We’re always looking for talents": "We’re always looking for talents",
  "Talk to Anton, he’s nice.": "Talk to Anton, he’s nice.",
  "Want to work with us?": "Want to work with us?",
  "Let us know :)": "Let us know :)",
  "Let us know!": "Let us know!"
}